import React from "react";
import { Switch, Route } from "react-router-dom";

// components
import Navbar from "./components/navbar.js";
import Home from "./components/home.js";
import About from "./components/about.js";
// import Reviews from "./components/reviews.js";
import Toolkit from "./components/toolkit.js";
import Contact from "./components/contact.js";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        {/* <Route exact path="/reviews" component={Reviews} /> */}
        <Route exact path="/toolkit" component={Toolkit} />
        <Route exact path="/contact" component={Contact} />
      </Switch>
    </div>
  );
}

export default App;

import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  box: {
    margin: "0 auto",
    maxWidth: "1000px",
  },
  title: {
    color: "#6C63FF",
    fontWeight: "1000",
    fontFamily: "Fjalla one",
    marginTop: "3rem",
    marginBottom: "3rem",
  },
  subtitle: {
    marginBottom: "3rem",
  },
  text: {
    marginBottom: "1rem",
  },
});

const Home = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Container className={classes.box}>
        <Typography variant="h1" className={classes.title}>
          MacGirl.DC
        </Typography>
        <Typography variant="h4" className={classes.subtitle}>
          A boutique technical support service supporting Macs and the people
          that use them.
        </Typography>
        <Typography variant="h6" className={classes.text}>
          Unlike a lot of techies who occasionally work on a Mac, Apple support
          is all I do — and all I want to do.
        </Typography>
        <Typography variant="h6" className={classes.text}>
          I listen to what you need, develop a solution with you, and work my
          magic. Questions are answered in everyday language. Computers
          shouldn’t be confusing and IPhones shouldn’t be intimidating.
        </Typography>
        <Typography variant="h6" className={classes.text}>
          My clients are mostly small businesses, but I enjoy supporting
          individuals at their homes as well. Different types of people and
          business require different solutions, and that is why I love being a
          consultant — the satisfaction in figuring out the best way to solve
          the problem.
        </Typography>
      </Container>
    </React.Fragment>
  );
};

export default Home;
